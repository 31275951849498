// dataFaqs.js

const dataFaqs = [
    {
      id: 1,
      title: 'What is an NFT?',
      text: 'An NFT (Non-Fungible Token) is a unique digital asset that represents ownership or proof of authenticity of a particular item or artwork on the blockchain.',
      show: '',
    },
    {
      id: 2,
      title: 'Why does NFT art have value? Why are NFTs so expensive?',
      text: `NFT art has value because of its uniqueness, proof of ownership, and the emotional connection individuals have with the artwork. The scarcity of NFTs and their verification on the blockchain contribute to their perceived worth. NFTs can be expensive due to factors such as their rarity, association with popular artists, market demand, and the historical significance they hold within the NFT ecosystem. The prices of NFTs are driven by market dynamics and the willingness of buyers to invest in these digital assets.`,
      show: '',
    },
    {
      id: 3,
      title: 'What are NFTs used for?',
      text: 'NFTs can be used to indicate ownership of rare assets which represents real-world items like real estate and artwork. It’s important to note however that purchasing an NFT that includes the copyright of the original artwork does not mean its copyright is automatically transferred to you. While you can sell the NFT itself, you are prohibited from reproducing or selling its content.',
      show: 'false',
    },
    {
      id: 4,
      title: 'Are NFTs a good investment?',
      text: `Yes, The investment potential of NFTs can vary and is subject to market conditions. While some NFTs have seen significant returns, it's important to approach NFTs as a speculative investment with inherent risks. Conduct thorough research, consider your financial goals, and be mindful of market volatility before investing in NFTs.`,
      show: '',
    },
    {
      id: 5,
      title: 'Who is NFTPros?',
      text: `NFTPro is a renowned NFT Artist/Digital Artist with over 5 years of experience in various NFT-related services. With an impressive portfolio, they have successfully collaborated with over 450 clients from more than 30 countries worldwide. NFTPro's expertise and artistic vision make them a trusted choice for individuals and businesses seeking top-quality NFT services.`,
      show: '',
    },
    {
      id: 6,
      title: 'How can I buy an NFT Service from you?',
      text: 'To purchase an NFT service from NFTPros, you can contact us from our contact section. Then we will guide you through the necessary steps to complete your project.',
      show: '',
    },
    // Add the NFT Collection FAQs
    {
      id: 7,
      title: 'What is an NFT collection?',
      text: 'An NFT collection is a curated group of digital assets, often centered around a specific theme, concept, or style. Each NFT in the collection is unique and tokenized on a blockchain, making it verifiable and secure.',
      show: '',
    },
    {
      id: 8,
      title: 'How can I start my own NFT collection?',
      text: 'To start an NFT collection, you need a creative concept, a series of digital assets, and a platform for minting NFTs. NFTPros can guide you through the process, including design, smart contract development, and deployment on a blockchain.',
      show: '',
    },
    {
      id: 9,
      title: 'What makes an NFT collection successful?',
      text: 'Success factors include originality, a strong narrative, community engagement, rarity, and utility such as exclusive perks or integration with games or events. Proper marketing and collaboration with influencers also play a vital role.',
      show: '',
    },
    {
      id: 10,
      title: 'Which blockchain should I choose for my NFT collection?',
      text: 'Ethereum is a popular choice for its widespread adoption, but platforms like Polygon, Solana, and Binance Smart Chain offer lower transaction fees and faster processing times. We can help you pick the blockchain that best fits your needs.',
      show: '',
    },
    {
      id: 11,
      title: 'How can I engage my audience with my NFT collection?',
      text: 'Build a community on platforms like Discord and Twitter, host interactive events, offer rewards or utilities, and maintain open communication. A strong community is key to the success of your NFT collection.',
      show: '',
    },
    // Cryptocurrency FAQs
    {
      id: 12,
      title: 'What is cryptocurrency?',
      text: 'Cryptocurrency is a digital currency secured by cryptography and based on blockchain technology. It is decentralized, meaning it operates without a central authority like a bank or government.',
      show: '',
    },
    {
      id: 13,
      title: 'How can I buy and sell cryptocurrency?',
      text: 'You can buy and sell cryptocurrencies on platforms like Binance, Coinbase, or Kraken. Create an account, verify your identity, and use a payment method such as bank transfer, credit card, or PayPal to trade.',
      show: '',
    },
    {
      id: 14,
      title: 'What are the most popular cryptocurrencies?',
      text: 'Bitcoin (BTC) and Ethereum (ETH) are the most popular. Other notable ones include Binance Coin (BNB), Solana (SOL), and Cardano (ADA). Each serves unique purposes in the blockchain ecosystem.',
      show: '',
    },
    {
      id: 15,
      title: 'Is cryptocurrency secure?',
      text: 'Cryptocurrency is secured by cryptography, making transactions safe. However, users must safeguard their wallets and private keys to avoid theft or loss.',
      show: '',
    },
    {
      id: 16,
      title: 'How do I store cryptocurrency safely?',
      text: 'Cryptocurrencies can be stored in wallets. For maximum security, use cold wallets like hardware wallets for long-term storage and hot wallets for frequent transactions.',
      show: '',
    },
    // Web 3.0 FAQs
    {
      id: 17,
      title: 'What is Web 3.0?',
      text: 'Web 3.0 is the next evolution of the internet, characterized by decentralization, blockchain technology, and user-controlled data. It enables peer-to-peer interactions without relying on centralized platforms.',
      show: '',
    },
    {
      id: 18,
      title: 'How does Web 3.0 differ from Web 2.0?',
      text: 'Web 2.0 is centralized, with companies controlling user data and services. Web 3.0 shifts control to users, leveraging blockchain for transparency, privacy, and decentralized applications (dApps).',
      show: '',
    },
    {
      id: 19,
      title: 'What are decentralized applications (dApps)?',
      text: 'dApps are applications built on blockchain technology that operate without a central authority. They offer greater transparency, security, and user control compared to traditional apps.',
      show: '',
    },
    {
      id: 20,
      title: 'What are the key technologies behind Web 3.0?',
      text: 'Key technologies include blockchain, smart contracts, decentralized storage (IPFS), cryptocurrencies, and interoperability protocols like Polkadot and Cosmos.',
      show: '',
    },
    {
      id: 21,
      title: 'How can Web 3.0 benefit businesses?',
      text: 'Web 3.0 provides businesses with new opportunities, such as creating decentralized marketplaces, offering tokenized rewards, enhancing security, and improving customer trust through transparency.',
      show: '',
    },
  ];
  
  export default dataFaqs;
  