
import img1 from '../images/svg/icon-1.svg'
import img2 from '../images/svg/icon-2.svg'
import img3 from '../images/svg/icon-3.svg'
import img4 from '../images/svg/icon-4.svg'



const dataPortfolio = [

    {
        id: 1,
        img: img1,
        title: 'NFT Creation and Development',
        desc: 'Transform your ideas into stunning digital assets with our end-to-end NFT creation services. From high-quality designs and generative art to seamless minting and metadata integration, we bring your NFT vision to life.'
    },
    {
        id: 2,
        img: img2,
        title: 'Web3 and Blockchain Solutions',
        desc: 'Build the future with our cutting-edge Web3 and blockchain services. We specialize in DApp development, smart contract creation and auditing, cryptocurrency payment integration, and blockchain consulting to ensure your project’s success.'
    },
    {
        id: 3,
        img: img3,
        title: 'Marketplace and Metaverse Development',
        desc: 'Launch your own NFT marketplace or step into the metaverse with our tailored solutions. We develop decentralized marketplaces and immersive virtual worlds with NFT integration, offering unparalleled user experiences.'
    },
    {
        id: 4,
        img: img4,
        title: 'Blockchain Gaming and Token-Based Ecosystems',
        desc: 'Create next-generation decentralized ecosystems with our expertise in DeFi, blockchain gaming, and token development. We provide seamless solutions to power innovative financial models and engaging experiences.'
    },


]

export default dataPortfolio;