import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import dataBlog from '../../assets/fake-data/data-blog';

Blog.propTypes = {
  data: PropTypes.array,
};

Blog.defaultProps = {
  data: dataBlog, // Use the imported data by default
};

function Blog(props) {
  const { data } = props;

  const dataBlock = {
    subheading: 'From Our Blog',
    heading: 'Read Our Recent News & Articles',
  };

  return (
    <section className="blog s2">
      <div className="shape right"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="block-text center">
              <h6 className="sub-heading">
                <span>{dataBlock.subheading}</span>
              </h6>
              <h3 className="heading pd">{dataBlock.heading}</h3>
            </div>
          </div>

          {data.slice(0, 3).map((item) => (
            <div key={item.id} className="col-md-4">
              <div className="blog-box">
                <div className="image">
                  <Link to="/blog-details">
                    <img src={item.img} alt="Blog" />
                  </Link>
                </div>
                <div className="content center">
                  <div className="meta">
                    <p>{item.time}</p>
                  </div>
                  <Link to="/blog-details" className="h5 title">
                    {item.title}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Blog;
