import React from 'react';
import PropTypes from 'prop-types';
import dataPortfolio from '../../assets/fake-data/data-portfolio';
import img from '../../assets/images/layouts/Portfolio Overview.png';

Portfolio.propTypes = {
    data: PropTypes.array,
};

function Portfolio({ data = dataPortfolio }) {
    const dataBlock = {
        subheading: 'Join NFT Portfolio',
        heading: 'Providing Expert NFT Innovation Services',
    };

    return (
        <section className="portfolio">
            <div className="shape"></div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-text center">
                            <h6 className="sub-heading">
                                <span>{dataBlock.subheading}</span>
                            </h6>
                            <h3 className="heading pd">{dataBlock.heading}</h3>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-6">
                        <div className="portfolio__left">
                            {data.map((item) => (
                                <div key={item.id} className="portfolio-box">
                                  
                                    <div className="icon">
                                        <img src={item.img} alt={`Step ${item.id}`} />
                                    </div>
                                    <div className="content">
                                        <h5 className="title">{item.title}</h5>
                                        <p>{item.desc}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-6">
                        <div className="portfolio__right">
                            <div className="image" data-aos="fade-left" data-aos-duration="2000">
                            <img src={img} alt="Portfolio Overview" style={{ width: "100%", maxWidth: "600px", height: "auto",  marginTop: "100px" }} />


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Portfolio;
