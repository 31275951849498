import React, { useState } from "react";
import PropTypes from "prop-types";
import { Accordion } from "react-bootstrap-accordion";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import img from "../../assets/images/layouts/img-faq.png";
import "react-lazy-load-image-component/src/effects/blur.css";
import dataFaqs from "../../assets/fake-data/data-faq"; // Import the updated data

Faq3.propTypes = {
  data: PropTypes.array,
};

function Faq3(props) {
  const { data } = props;

  const [dataBlock] = useState({
    subheading: "FAQs",
    heading: "Frequently Asked Questions",
    desc: "Below is a list of frequently asked questions and answers from partners and 3D artists. Please check this FAQ first before contacting us.",
  });

  const [dataTab] = useState([
    { id: 1, title: "NFT Arts" },
    { id: 2, title: "NFT Collections" },
    { id: 3, title: "Cryptocurrency" },
    { id: 4, title: "Web 3.0 Developments" },
  ]);

  return (
    <section className="faq s3">
      <div className="container">
        <div className="row" style={{ flexDirection: "row-reverse" }}>
          <div className="col-xl-6 col-md-12">
            <div className="block-text">
              <h6 className="sub-heading">
                <span>{dataBlock.subheading}</span>
              </h6>
              <h3 className="heading">{dataBlock.heading}</h3>
              <p>{dataBlock.desc}</p>
            </div>
            <div className="faq__main flat-tabs">
              <Tabs>
                <TabList className="menu-tab">
                  {dataTab.map((tab) => (
                    <Tab className="fs-14 h6" key={tab.id}>
                      {tab.title}
                    </Tab>
                  ))}
                </TabList>

                {dataTab.map((tab) => (
                  <TabPanel key={tab.id} className="content-tab">
                    <div className="content-inner">
                      <div className="flat-accordion">
                        {tab.title === "NFT Arts" &&
                          dataFaqs.slice(0, 5).map((faq) => ( 
                            <Accordion
                              key={faq.id}
                              title={faq.title}
                              className="flat-toggle h6"
                            >
                              <p className="toggle-content">{faq.text}</p>
                            </Accordion>
                          ))}
                        {tab.title === "NFT Collections" &&
                          dataFaqs.slice(6, 11).map((faq) => (
                            <Accordion
                              key={faq.id}
                              title={faq.title}
                              className="flat-toggle h6"
                            >
                              <p className="toggle-content">{faq.text}</p>
                            </Accordion>
                          ))}
                        {tab.title === "Cryptocurrency" &&
                          dataFaqs.slice(11, 16).map((faq) => (
                            <Accordion
                              key={faq.id}
                              title={faq.title}
                              className="flat-toggle h6"
                            >
                              <p className="toggle-content">{faq.text}</p>
                            </Accordion>
                          ))}
                        {tab.title === "Web 3.0 Developments" &&
                          dataFaqs.slice(16, 22).map((faq) => (
                            <Accordion
                              key={faq.id}
                              title={faq.title}
                              className="flat-toggle h6"
                            >
                              <p className="toggle-content">{faq.text}</p>
                            </Accordion>
                          ))}
                      </div>
                    </div>
                  </TabPanel>
                ))}
              </Tabs>
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="faq-image">
              <LazyLoadImage src={img} alt="" effect="blur" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faq3;
