import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import img from "../../assets/images/layouts/avt-05.png";
import Blog from "../blog/Blog";
import "react-lazy-load-image-component/src/effects/blur.css";

Project.propTypes = {
  data: PropTypes.array,
};

function Project(props) {
  const { data } = props;

  const [dataBlock] = useState({
    subheading: "Our Speciality",
    heading: "OUR Projects",
  });

  const [likes, setLikes] = useState([]);


  useEffect(() => {
    const randomLikes = data.map(() => Math.floor(Math.random() * (5000 - 1000 + 1)) + 1000);
    setLikes(randomLikes);
  }, [data]);
  
  return (
    <section className="project">
      <div className="shape right"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="block-text center">
              <h6 className="sub-heading">
                <span>{dataBlock.subheading}</span>
              </h6>
              <h3 className="heading">{dataBlock.heading}</h3>
            </div>

            <Swiper
              className="project-swiper"
              spaceBetween={30}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                991: {
                  slidesPerView: 3,
                },
              }}
              loop={true}
              modules={[Navigation, Pagination]}
              navigation
              pagination={{
                clickable: true,
              }}
            >
              {data.map((idx, index) => (
                <SwiperSlide key={idx.id}>
                  <div className="swiper-slide">
                    <div className="project-boxs">
                      <div className="nft-items">
                        <div className="card-media">
                          <a href='/portfolio'>
                            {/* <img src={idx.img} alt="NFTPros" /> */}
                            <LazyLoadImage
                              alt="NFTPros"
                              effect="blur"
                              src={idx.img}
                            />
                          </a>
                        </div>
                        <div className="card-title">
                          <Link to="portfolio" className="h5">
                            {idx.title}
                          </Link>
                        </div>
                        <div className="meta-info">
                          <div className="author">
                            <div className="avatar">
                              <img src={img} alt="NFTPros" />
                            </div>
                            <div className="info">
                              <span>Designed By</span>
                              <Link to="/portfolio" className="h6">
                                NFTPros
                              </Link>
                            </div>
                          </div>
                          <Link to="#" className="wishlist-button heart">
                            <svg
                              width="13"
                              height="13"
                              viewBox="0 0 13 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.75 4.3125C11.75 2.86292 10.5256 1.6875 9.01533 1.6875C7.88658 1.6875 6.91708 2.34433 6.5 3.28175C6.08292 2.34433 5.11342 1.6875 3.98408 1.6875C2.475 1.6875 1.25 2.86292 1.25 4.3125C1.25 8.52417 6.5 11.3125 6.5 11.3125C6.5 11.3125 11.75 8.52417 11.75 4.3125Z"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>

                            <span className="number-like"> {likes[index]}</span>
                          </Link>
                        </div>
                        {/* <div className="card-bottom style-explode">
                                            <div className="price">
                                                <span className="icon-logo-01"></span>
                                                <div className="price-details">
                                                    <span> Current Bid</span>
                                                    <h6>0.45 ETH</h6>
                                                </div>
                                            </div>
                                            <div className="button-place-bid">
                                                <Link to="" data-toggle="modal" data-target="#popup_bid" className="sc-button"><span>Place Bid</span></Link>
                                            </div>
                                        </div> */}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>

    

     
    </section>
  );
}

export default Project;
